import { GetUserDataPouch } from '../../context/puchdb/rutines';
import { Button, Grid, IconButton } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { useContext, useEffect } from 'react';
import { Context } from '../../context/Context';
import LogoutIcon from '@mui/icons-material/Logout';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import { DeletUserData } from '../../context/puchdb/rutines';


const LoggedBar = () => {
    const dataContext = useContext(Context);
    useEffect(() => {
        if (dataContext.LoggedUser == null) {
            GetUserDataPouch(dataContext);
        }
    }, [])
    const Logout = () => {
        DeletUserData(dataContext);
    }

    return (<>
        {dataContext.LoggedUser == null ? <></> :
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={9}>
                    <p className='logged'>Logged as: {dataContext.LoggedUser.userName} </p>
                </Grid>
                {dataContext.TestPaymentMode === 0 ? <></> : <>
                    <Grid item xs={1}>
                        <p className='logged'>Test Mode: ON </p>
                    </Grid>
                </>}

                {dataContext.LoggedUser.rol.includes('Campo') ? <></> : <>
                    <Grid item xs={1}>
                        <IconButton variant="outlined" href="Dashboard"><SpaceDashboardIcon /></IconButton>
                    </Grid>
                </>}
                <Grid item xs={1}>
                    <IconButton variant="outlined" onClick={() => { Logout() }}><LogoutIcon /></IconButton>
                </Grid>
            </Grid>



        }
    </>)
}
export default LoggedBar