
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import arrow from "../../assets/Images/arrow-inicio.png"
import { useContext, useEffect } from 'react';
import { Context } from '../../context/Context'
import HeaderNologo from '../Universals/HeaderNoLogo';
import CountrySelector from "./CountrySelector";
import { useManageForm } from "./hooks/useManageForm";
import { NotificationContext } from "../../context/NotificationContext";


function DataVerification() {
  const datacontext = useContext(Context);
  const {  askForNotificationPermission } = useContext(NotificationContext);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const navigate = useNavigate();
  const {UpdateFormData,FormData} = useManageForm();
  useEffect(() => {
    askForNotificationPermission();
    if (datacontext.Data.Datos.detalles.length === 0) navigate("/TravelData");
  },[])

  const [fail, setFail] = React.useState(false);
  const failName = FormData.name.length <= 1;
  const failSurname = FormData.surname.length <= 1;
  const failPassport = !(FormData.passport.length < 14 && FormData.passport.length>4);
  return (<div className="workzone">
    <HeaderNologo navigate="/NumberofUsers" />
    <h1>{txt[32]}</h1>
    <h1>{txt[21] + (datacontext.userid + 1)}</h1>
    {fail ? <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">
        <AlertTitle>{txt[33]}</AlertTitle>
        {txt[34]}
      </Alert>
    </Stack> : <></>}

    <CountrySelector initialValue={FormData.nationality} UpdateFormData ={UpdateFormData}/>
    <li>
      {failName && fail ? <p className="err">{txt[35]}</p> : <></>}

      <strong id='form1'>{txt[36]}</strong><input type="text" id="forminput" name="number" value={FormData.name} onChange={((e)=>{UpdateFormData("nombre",e)})} />

    </li>
    <li>
      {failSurname && fail ? <p className="err">{txt[37]}</p> : <></>}
      <strong id='form1'>{txt[38]}</strong><input type="text" id="forminput" name="email" value={FormData.surname} onChange={((e)=>{UpdateFormData("apellido",e)})} />

    </li>
    <li>
      {failPassport && fail ? <p className="err">{txt[39]}</p> : <></>}
      <strong id='form1'>{txt[40]}</strong><input type="text" id="forminput" name="email" value={FormData.passport} onChange={((e)=>{UpdateFormData("pasaporte",e)})} />

    </li>

    <li>
      <strong id='form1'>{txt[14]}</strong><input type="text" id="forminput" name="email" value={FormData.email} onChange={((e)=>{UpdateFormData("correo",e)})} />
    </li>

    <li>
      <strong id='form1'>{txt[60] ? txt[60] : "Phone Number"}</strong><input type="number" id="forminput" name="email" value={FormData.phone}  onChange={((e)=>{UpdateFormData("telefono",e)})} />
    </li>

    <li>
      <strong id='form1'>{txt[12]}</strong><p id="forminput"  >{FormData.departureDate}</p>
    </li>
    <button onClick={() => {
      if (!failName && !failSurname && !failPassport) {
        setFail(false);
        navigate("/NumberofUsers");
      } else setFail(true);
    }} className="stylebtn1" >{txt[18]}<img id="continue" src={arrow} alt="Visitax logo"></img></button>





  </div>)
} export default DataVerification;