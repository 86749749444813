
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useContext } from 'react';
import { Context } from '../../../context/Context'
function CVV(props) {
    const datacontext = useContext(Context);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlerCVV = (event) => {
        const InputPattern = /^[0-9]{0,4}$/;
        const validationPattern = /^[0-9]{3,4}$/;
        const cvv = event.target.value



        if (InputPattern.test(cvv)) {
            props.setCvvValidation(cvv.length > 2);
            if (event.target.value.length < 5) {
                datacontext.setcard(
                    {
                        ...datacontext.card,
                        cvv2: event.target.value
                    }
                )

            }
        }


    }



    return (<>
        <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">CVV</InputLabel>
            <OutlinedInput

                error={false}
                id="outlined-adornment-password"
                type={showPassword ? 'number' : 'password'}

                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label="CVV"
                onChange={handlerCVV}
                disabled={props.waitingResponse}
                value = {datacontext.card.cvv2}
            />

        </FormControl>
    </>)
} export default CVV