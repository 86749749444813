import { Context } from '../../context/Context';
import { useContext,useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Notifications from './Notifications';


function LenguageSelector() {
    const datacontext = useContext(Context);
    const handleChange = (event) => {
        datacontext.setLenguage(event.target.value);
    };
    useEffect(() => {
        datacontext.getconfigurations(false);
    })

    return (
        datacontext.myConfig.umaValue == null ? <></> : <div>
            <Notifications/>
            <FormControl sx={{ m: 1, minWidth: 50 }}>

                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={datacontext.lenguage}
                    onChange={handleChange}
                    autoWidth
                >
                    {
                        datacontext.myConfig.lenguages.map((value, id) => {

                            return (
                                <MenuItem key={id} value={id}> <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${datacontext.myConfig.flags[id].toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${datacontext.myConfig.flags[id].toLowerCase()}.png`}
                                    alt=""
                                />{" " + value}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </div>)

} export default LenguageSelector;