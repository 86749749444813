import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../Chart';
import Deposits from '../Deposits';
import Orders from '../Orders';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import FilterDash from './FiltersDash';
import { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../context/Context';
import SimpleBackdrop from '../../Universals/BackDrop';
import { getCurrentDate, month, year } from '../../../data/Datos';
import Reports from '../Reports/Reports';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BarChar from '../BarChar';
function PrincipalDash(props) {


    const [report, setReport] = React.useState('pdf');
    const handlereportChange = (event, newValue) => {
        setReport(newValue);
    };
    const [filter, setFilter] = useState("Today");
    const [ListData, setListData] = useState(null);
    const [DepositsData, setDepositsData] = useState(null);
    const [IncidentsData, setIncidentsData] = useState(null);
    const [TransactionDetailsData, setTransactionDetails] = useState(null);
    const [TerminalsData, setTerminalsData] = useState({});
    const [request, setnewRequest] = useState(true);
    const [requestDeposits, setRequestDeposits] = useState(false);
    const [requestIncidents, setRequestIncidents] = useState(false);
    const [requestTermials, setRequestTerminals] = useState(false);
    const [page, setPage] = useState(1);
    const registPerPage = 10;
    const [FiltersData, setFilterData] = useState([
        "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage,
        "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage,
        "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage,
        ""
    ]);
    const [FiltersData2, setFilterData2] = useState([
        "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=H",
        "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=D",
        "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=M",
        ""
    ]);

    const [FiltersData3, setFilterData3] = useState([
        "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1",
        "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1",
        "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1",
        ""
    ]);
    const datacontext = useContext(Context);



    const OnDataRecived = (response) => {
        if (response.data.data == null) setListData("Empty");
        else setListData(response.data.data)
        setnewRequest(false);
    }
    const OnDataFailed = (response) => {
        setnewRequest(false);
    }

    const OnDataDepositRecived = (response) => {
        if (response.data.data == null) setDepositsData("Empty");
        else setDepositsData(response.data.data)
        setRequestDeposits(false);

    }
    const OnDataDepositFailed = (response) => {
        setRequestDeposits(false);
    }
    const OnDataIncidentsRecived = (response) => {
        console.log(response.data.data);
        if (response.data.data == null) setIncidentsData("Empty");
        else setIncidentsData(response.data.data)
        setRequestIncidents(false);

    }
    const OnDataIncidentsFailed = (response) => {
        setRequestIncidents(false);
    }
    const OnDataTransactionDetails = (response) => {
        if (response.data.data == null) setTransactionDetails("Empty");
        else setTransactionDetails(response.data.data)
        setRequestIncidents(false);

    }
    const OnDataTransactionFailed = (response) => {
        setRequestIncidents(false);
    }

    const OnDataTerminals = (response) => {
        if (response.data.data == null) setTerminalsData({});
        else setTerminalsData(response.data.data)
        setRequestTerminals(false);

    }
    const OnDataTerminalsFailed = (response) => {
        setRequestTerminals(false);
    }




    const newRequest = () => {

        setListData(null);
        let url3 = FiltersData[3];
        let pageNumberIndex = url3.indexOf("&PageNumber=");
        if (pageNumberIndex !== -1) {
            url3 = url3.substring(0, pageNumberIndex);
        }
        setFilterData([
            "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage,
            "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage,
            "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage,
            url3 + "&PageNumber=" + page + "&PageSize=" + registPerPage])
        if (filter.includes("Today")) {
            datacontext.getFilterData(OnDataRecived, OnDataFailed, FiltersData[0], props.Reports);
            if (props.Reports) datacontext.getTransactionDetails(OnDataTransactionDetails, OnDataTransactionFailed, FiltersData[0]);
        }
        if (filter.includes("Month")) {
            datacontext.getFilterData(OnDataRecived, OnDataFailed, FiltersData[1], props.Reports);
            if (props.Reports) datacontext.getTransactionDetails(OnDataTransactionDetails, OnDataTransactionFailed, FiltersData[1]);
        }
        if (filter.includes("Year")) {
            datacontext.getFilterData(OnDataRecived, OnDataFailed, FiltersData[2], props.Reports);
            if (props.Reports) datacontext.getTransactionDetails(OnDataTransactionDetails, OnDataTransactionFailed, FiltersData[2]);
        }
        if (filter.includes("Select")) {
            datacontext.getFilterData(OnDataRecived, OnDataFailed, FiltersData[3], props.Reports);
            if (props.Reports) datacontext.getTransactionDetails(OnDataTransactionDetails, OnDataTransactionFailed, FiltersData[3]);
        }

    }
    const newDepositsRequest = () => {
        setDepositsData(null);
        if (filter.includes("Today")) datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, FiltersData2[0]);
        if (filter.includes("Month")) datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, FiltersData2[1]);
        if (filter.includes("Year")) datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, FiltersData2[2]);
        if (filter.includes("Select")) datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, FiltersData2[3]);
    }

    const newTerminalReuest = () => {
        setDepositsData(null);
        if (filter.includes("Today")) datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, FiltersData3[0]);
        if (filter.includes("Month")) datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, FiltersData3[1]);
        if (filter.includes("Year")) datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, FiltersData3[2]);
        if (filter.includes("Select")) datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, FiltersData3[3]);
    }
    useEffect(() => {
        if (requestDeposits === true) {  newDepositsRequest(); }
    }, [requestDeposits])
    useEffect(() => {
        if (request === true) { newRequest(); }
    }, [request])
    useEffect(() => {
        if (requestIncidents === true) { datacontext.getIncidents(OnDataIncidentsRecived, OnDataIncidentsFailed) }
    }, [requestIncidents])

    useEffect(() => {
        if (requestTermials === true) {  newTerminalReuest() }
    }, [requestTermials])
    useEffect(() => {
        setRequestDeposits(true);
        setRequestIncidents(true);
        setRequestTerminals(true);
    }, [])
    useEffect(() => {
        setnewRequest(true);
    }, [page])
    useEffect(() => {
        if (request === false) setnewRequest(true);
    }, [props.Reports])

    return (<>

        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
        
                overflow: 'auto',
            }}
        >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <FilterDash filter={filter} setFilter={setFilter} setRequestDeposits={setRequestDeposits} setRequestTerminals={setRequestTerminals} setnewRequest={setnewRequest} FiltersData={FiltersData} FiltersData2={FiltersData2} FiltersData3={FiltersData3} setFilterData={setFilterData} page={page} registPerPage={registPerPage} setFilterData2={setFilterData2} setFilterData3={setFilterData3}/>
                {props.Reports == true ?
                    <>
                        <Tabs
                            value={report}
                            onChange={handlereportChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="pdf" label="PDF" />
                            <Tab value="xls" label="XLS" />
                        </Tabs>
                        <Reports report={report} data={{ DepositsData: DepositsData, ListData: ListData, filter: filter, FiltersData: FiltersData,TransactionDetailsData:TransactionDetailsData }} />


                    </>

                    : <>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Chart data={DepositsData} type={1} />

                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Deposits data={DepositsData} type={1} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Chart data={DepositsData} type={2} />

                                </Paper>
                            </Grid>
                            {/* Recent Deposits */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Deposits data={ListData} type={2} />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={12} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <BarChar Data={TerminalsData} />

                                </Paper>
                            </Grid>


                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    {ListData == null ? <SimpleBackdrop /> : <Orders data={ListData} page={page} setPage={setPage} registPerPage={registPerPage} setnewRequest={setnewRequest} type={1} />}
                                </Paper>
                            </Grid>

                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    {IncidentsData == null ? <SimpleBackdrop /> : <Orders data={{ allPaymentRegister: IncidentsData }} page={page} setPage={setPage} registPerPage={registPerPage} setnewRequest={setRequestIncidents} type={2} />}

                                </Paper>
                            </Grid>
                        </Grid>

                    </>}



            </Container>






        </Box>


    </>);
} export default PrincipalDash;