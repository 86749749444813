import { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import { createDocument, readDocumentById, updateDocumentById } from "../../../context/puchdb/pouchdbData";
import { openpay3ds } from "../../../data/Datos";


export const useOPPayment = ({ setPaymentFail, setWaitingResponse, setID }) => {
    const datacontext = useContext(Context);
    const dbId = "dataPayment"
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [openpayOptions, setOpenPayOptions] = useState({
        openpayid: null,
        apikeyopenpay: null,
        sandboxmode: datacontext.Sandbox
    });
    const [deviceSessionId, setDeviceSessionId] = useState(null);
    const [source_id, setSource_id] = useState(null);
    const [tokenid, setTokenid] = useState(null);
    const [manualid, setManualid] = useState(null);


    const ConfigureApiOpenPay = () => {
        datacontext.myConfig.allconfigArray.map((value) => {
            if (value.concept.includes("OPENPAYID")) {
                setOpenPayOptions(prev => ({ openpayid: value.configurationInfo, apikeyopenpay: prev.apikeyopenpay, sandboxmode: prev.sandboxmode }))
            }
            if (value.concept.includes("PUBLICAPIKEYOPENPAY")) {
                setOpenPayOptions(prev => ({ openpayid: prev.openpayid, apikeyopenpay: value.configurationInfo, sandboxmode: prev.sandboxmode }))
            }
            /*if (value.concept.includes('Sandbox')) {
                setOpenPayOptions(prev => ({ openpayid: prev.openpayid,apikeyopenpay: prev.apikeyopenpay, sandboxmode: Number(value.configurationInfo) === 0 ? false : true }))
            }*/

        })

    }

    const successCallbacktoken = async (response) => {
        setSource_id(response.data.id);
    };
    const errorCallbacktoken = (response) => {
        console.log("Error al generar el token");
        console.log(response);
        FailResponsePay();
      
    }

    const CreateToken = () => {
        try {
            window.OpenPay.token.create(datacontext.card, successCallbacktoken, errorCallbacktoken, {
                use_3d_secure: true,
            });
        } catch (e) { console.log(e) }
    }


    const success3Ds = async (res) => {

        if (res.data.method.includes('card') && (res.data.status.includes('in_progress') || res.data.status.includes("charge_pending"))) {
            const userDoc = { _id: dbId, ...datacontext.Data.Datos };
            try {
                createDocument(userDoc).then(window.location.href = res.data.payment_method.url).catch(updateDocumentById(dbId, userDoc).then(window.location.href = res.data.payment_method.url).catch(console.log()));

            } catch (err) {
                if (err.status === 409) {
                    updateDocumentById(dbId, userDoc).then(window.location.href = res.data.payment_method.url).catch(console.log());
                }
            }

        }

    };

    const fail3Ds = (response) => {
        console.log(response);
        FailResponsePay();
    }



    const AskFor3ds = () => {
        console.log("datacontext.card");
        console.log(datacontext.card);
        datacontext.postchargeop3ds(success3Ds, fail3Ds, {
            ...openpay3ds,
            "redirect_url": window.location.hostname + "/TotalPayment",
            "amount": datacontext.Data.Datos.importe,
            "source_id": source_id,
            "device_session_id": deviceSessionId,
            "description": datacontext.Data.Datos.detalles[0].pasaporte,
            "customer": {
                "name": datacontext.card.holder_name,
                "last_name": "",
                "phone_number": datacontext.card.phone_number,
                "email": datacontext.card.email
            },
        })
    }


    const OKResponsePay = (comprobante) => {
        datacontext.setComprobante(comprobante);
        navigate("/PaymentConfirmation");
        setPaymentFail(null);
        setWaitingResponse(false);


    }
    const FailResponsePay = (message = "Failed to generate payment") => {
        setPaymentFail(message);
        setWaitingResponse(false);
        setID(null)
        setState(0);
        ConfigureApiOpenPay();
        readDocumentById(dbId).then(doc => {
            datacontext.UpdateData(doc);
            navigate("/TotalPayment");
        });

    }


    const manageResponse = (response) => {
        try {

            switch (response.status) {
                case 200:
                    OKResponsePay(response.data.data.data[0].comprobante);
                    break;
                case 201:
                    OKResponsePay("Empty Comprobant:" + response.data.data.details.body);
                    break;
                case 202:
                    OKResponsePay("Empty Comprobant:" + response.data.data.details.body);
                    break;
                case 400:
                    FailResponsePay(txt[48]);
                    break;
                default:
                    FailResponsePay(txt[48]);
                    break;
            }


        } catch (e) { console.log(e) }


    }

    const loadUserData = async (doc) => {
        readDocumentById('logged_user').then(user => {
            datacontext.UpdateData(doc);
            if (user) {

                datacontext.setLoggedUser(user);
                datacontext.UpdateTerminal(user.Terminal);




                datacontext.postPayment(manageResponse, {
                    ...doc,
                    card: null,
                    transaction_id: tokenid
                }, 1, user.token);

            } else {
                datacontext.postPayment(manageResponse, {
                    ...doc,
                    card: null,
                    transaction_id: tokenid
                }, 1);
            }
        });
    }



    const CompletePayment = () => {
        readDocumentById(dbId).then(doc => {
            datacontext.UpdateData(doc);
            loadUserData(doc);
        });

    }

    const configureApi = () => {
        setState(1);
    }
    const makePayment = () => {
        setState(2);
    }
    const CompletePay = (token,manual = false) => {
       if (manual){
         setManualid(token);
       }
       else{
        setTokenid(token)
       }
       

    }


    useEffect(() => {
        if (window.OpenPay && openpayOptions.openpayid != null && openpayOptions.apikeyopenpay != null) {
            window.OpenPay.setId(openpayOptions.openpayid);
            window.OpenPay.setApiKey(openpayOptions.apikeyopenpay);
            window.OpenPay.setSandboxMode(openpayOptions.sandboxmode);
            const deviceDataId = window.OpenPay.deviceData.setup("paymentForm");
            setDeviceSessionId(deviceDataId);

        }
    }, [openpayOptions]);

    useEffect(() => {
        if (source_id != null) {
            setState(3);
        }
    }, [source_id]);


    useEffect(() => {
        try {
            if (manualid != null) {
                datacontext.postPayment(manageResponse, {
                    ...datacontext.Data.Datos,
                    card: null,
                    transaction_id: manualid,
                    manual :true,
                }, 1);
                console.log();
            }
        } catch (e) {
            console.log(e)
        }

    }, [manualid]);


    useEffect(() => {
        try {
            if (tokenid != null) {
                setState(4);
            }
        } catch (e) {
            console.log(e)
        }

    }, [tokenid]);




    useEffect(() => {
        switch (state) {
            case 1:
                ConfigureApiOpenPay();
                break;
            case 2:
                CreateToken();
                break;
            case 3:
                AskFor3ds();
                break;
            case 4:
                CompletePayment();
                break;

        }
    }, [state])


    return {
        configureApi,
        makePayment,
        CompletePay
    }
}