const contactmail = "contact.us@satq.qroo.gob.mx"
export const myConfigstructure = {
  umaValue: null,
  price: null,
  lenguages:[],
  flags: [],
  txt: []

}

export const myConfig = {
  umaValue: null,
  price: null,
  lenguages: [
    "English",
  ],
  flags: [
    "US",
  ],
  txt: [
    {
      1: "Start",
      2: "Welcome to Visitax, an authorized platform by the State Government of Quintana Roo for the collection and supervision of the payment of the fee stipulated in Article 51-Octies of the Law of Rights of the State of Quintana Roo, known as Visitax",
      3: "At Visitax, we are dedicated to ensuring a seamless and efficient experience for all foreign visitors upon their arrival in the state",
      4: "For any inquires or clarification, our support team is available during the following hours",
      5: "Support Hours:",
      6: "Monday to Friday from 8:00 AM to 5:00 PM",
      7: "Feel free to reach out to us via email at:",
      8: "Email:" + contactmail,
      9: "Make your payment",
      10: "Recover your receipt",
      11: "D A T A",
      12: "Departure date:",
      13: "Number of visitors:",
      14: "E-mail",
      15: "Confirm your e-mail",
      16: "I accept terms and conditions",
      17: "In accordance with the Federal Law on Protection of Personal Data Held by Private Parties , Visitax establishes the following terms and conditions for the responsible handling of data provided by the user: Visitax, platform authorized by the government of the State of Quintana Roo.",
      18: "Next",
      19: "Users Number",
      20: "Add user",
      21: "User ",
      22: "Start registration by scanning passport",
      23: "Start manual registration",
      24: "Successful",
      25: "Identity Verification",
      26: "Have your passport ready to scan",
      27: "Enable camera permissions on your device",
      28: "Ensure that there is adequate lighting",
      29: "CAPTURE FRONT VIEW OF YOUR PASSPORT",
      30: "Hold your passport open at the photo page (showing your photo and personal details).",
      31: "Repeat capture",
      32: "Data Verification",
      33: "Error",
      34: "Please complete the form.",
      35: "Please enter your name",
      36: "Name",
      37: "Please enter your surname",
      38: "Surname:",
      39: "Please enter a valid passport",
      40: "Passport Number:",
      41: "Identification completed",
      42: "Identify next user",
      43: "Users Confirmation",
      44: "Total Payment",
      45: "Choose your payment method:",
      46: "Warning",
      47: "Please enter valid data",
      48: "An error has occurred while processing your payment.",
      49: "Pay",
      50: "Card Number",
      51: "Expire",
      52: "Payment Confirmation",
      53: "Please check your e-mail to obtain your QR code or",
      54: "Click here",
      55: "Enter your passport",
      56: "You have a valid receipt",
      57: "We could not find a valid receipt",
      58: "Click here to get your receipt",
      59: "Total",
      60: "Phone Number",
      61: "Privacity Notice",
      62: "Choose a nationality",
      63: "I agree to receive promotions and information"
    },
   
  ]
}