import NavigationIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import LenguageSelector from "../Universals/LenguageSelector"
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';


function HeaderNologo(props) {
    return (
        <div className="backgroundhead2">
            <Link style={{ textDecoration: 'none' }} to={props.navigate}>
                <IconButton aria-label="back" size="large">
                    {props.navigate ==="/"?<HomeIcon/>:<NavigationIcon/>}
                </IconButton>
            </Link>
            <LenguageSelector />
        </div>
    )

} export default HeaderNologo;