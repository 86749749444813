import * as React from 'react';
import MasterCard from '../../assets/Images/Mastercard-logo.png';
import Visa from '../../assets/Images/Visa_Logo.png';
import AmericanExpress from '../../assets/Images/American_Express_logo.png';
import OpenPayLogo from '../../assets/Images/logo-openpay.im1669747112914im.avif';
import KushkiLogo from '../../assets/Images/Kushki.png';
import line from '../../assets/Images/divider-numU.svg';
import CardNumber from './subcomponents/CardNumer';
import Dateexpire from './subcomponents/Dateexpire';
import { Stack, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CVV from './subcomponents/CVV';

import { useLocation, useNavigate } from 'react-router-dom';
import Amoount from "../Universals/Amount";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '../Universals/CircularProgress';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/Context';
import HeaderNologo from '../Universals/HeaderNoLogo';
import { useOPPayment } from './hooks/useOPPayment';
import { useKushkiPayment } from './hooks/useKushkiPayment';
import ComplementariForm from './subcomponents/ComplementariForm';
import { ThemeProvider } from '@mui/material/styles';
import { Link } from '@mui/material';

function TotalPayment() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const location = useLocation(); // Obtener la ubicación actual
    const queryParams = new URLSearchParams(location.search);
    const [PaymentGateway, setPaymentGateWay] = useState(0);
    const [id, setID] = useState(queryParams.get('id'));
    const navigate = useNavigate();

    // Nuevo estado para el tipo de pago
    const [paymentType, setPaymentType] = useState('');
    const [approvalCode, setApprovalCode] = useState('');
    const [selectorPermision, setSelectorPermision] = useState(false);

    const chooseanaction = () => {
        try {
            switch (PaymentGateway) {
                case 1:
                    configureApi();
                    break;
                case 2:
                    configureApiKushki();
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (id && datacontext.myConfig.allconfigArray) {
            const tokenId = id;
            CompletePay(tokenId);
        } else chooseanaction();
    }, [PaymentGateway, datacontext.myConfig]);



    useEffect(() => {
        if(datacontext.LoggedUser && !datacontext.LoggedUser.rol.includes("Campo"))setSelectorPermision(true);
        else{
            setSelectorPermision(false);
            setPaymentType("digital");
        }

    }, [datacontext.LoggedUser]);

    useEffect(() => {
        try {
            datacontext.myConfig.allconfigArray.map((value) => {
                if (value.concept.includes('PaymentGateway')) {
                    setPaymentGateWay(Number(value.configurationInfo));
                }
            });
        } catch (e) {
            console.log(e);
        }
    }, [datacontext.myConfig]);

    useEffect(() => {
        if (id) return;

        try {
            if (datacontext.Data.Datos.detalles.length === 0 && !id) navigate('/TravelData');
        } catch (e) {
            console.log(datacontext.Data);
            console.log(e);
        }
    }, []);

    const [cardtype, setCardType] = useState('Unknown');
    const [expireValidation, setexpireValidation] = useState(false);
    const [DataError, setdataError] = useState(false);
    const [CvvValidation, setCvvValidation] = useState(false);
    const [waitingResponse, setWaitingResponse] = useState(false);
    const [paymentFail, setPaymentFail] = useState(null);

    const cardSelector = () => {
        switch (cardtype) {
            case 'visa':
                return <img className="visa" src={Visa} />;
            case 'mastercard':
                return <img className="visa" src={MasterCard} />;
            case 'amex':
                return <img className="visa" src={AmericanExpress} />;
            default:
                return <></>;
        }
    };

    const PaymentValidation = () => {
        const cardValidation = cardtype !== 'Unknown';
        const nameValid = datacontext.card.holder_name.length > 1;
        const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const emailValid = regexEmail.test(datacontext.card.email);
        if (cardValidation && expireValidation && CvvValidation && nameValid && emailValid) {
            setdataError(false);
            return true;
        }
        setdataError(true);
        return false;
    };

    const ManageApiAsk = () => {

        if (paymentType === 'physical') {
            setWaitingResponse(true);
            if (approvalCode.length > 5 && approvalCode.length < 10) {
                setdataError(false);
                CompletePay(approvalCode, true);
            }
            else {
                setdataError(true);
            }
        } else {

            if (PaymentValidation()) {
                setWaitingResponse(true);


                switch (PaymentGateway) {
                    case 1:
                        makePayment();
                        break;
                    case 2:
                        makePaymentkushi();
                        break;
                    default:
                        break;
                }
            }
        }


    };

    const { configureApi, makePayment, CompletePay } = useOPPayment({
        setPaymentFail,
        setWaitingResponse,
        setID,
    });

    const { configureApiKushki, makePaymentkushi } = useKushkiPayment({
        setPaymentFail,
        setWaitingResponse,
    });

    const printTotal = () => {
        try {
            return datacontext.Data.Datos.importe;
        } catch (e) {
            console.log(e);
            return <></>;
        }
    };

    return (
        <>
            {waitingResponse || id ? <CircularProgress /> : <></>}

            <div className={waitingResponse || id ? 'workzoneopacity' : 'workzone'}>
                <HeaderNologo navigate="/ConfirmationUsers" />
                <h1>{txt[44]}</h1>

                {id ? (
                    <></>
                ) : (
                    <>
                        <h5>${printTotal()} MXN</h5>
                        <h3>{txt[45]}</h3>



                        {/* Show input for approval code if physical card is selected */}


                        {/* Show digital card form if digital card is selected */}


                        <div className="cardzone1">
                            <div className="divlogos">
                                <img className="visa" src={Visa} />
                                <img className="mastercard" src={MasterCard} />
                                <img className="mastercard" src={AmericanExpress} />
                                {false ? (
                                    <></>
                                ) : (
                                    <>
                                        {PaymentGateway == 1 ? (
                                            <img className="openpay" src={OpenPayLogo} />
                                        ) : (
                                            <img className="openpay" src={KushkiLogo} />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <>
                            {DataError ? (
                                <Alert severity="warning">
                                    <AlertTitle>{txt[46]}</AlertTitle>
                                    {txt[47]}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            <ThemeProvider theme={datacontext.primaytheme}>
                                <div id="PayForm">

                                    {/* ComboBox for selecting payment type */}
                                    {selectorPermision && <FormControl fullWidth>
                                        <InputLabel id="payment-type-label">Choose your payment method</InputLabel>
                                        <Select
                                            labelId="payment-type-label"
                                            id="payment-type"
                                            value={paymentType}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                            label="Choose your payment method"
                                        >
                                            <MenuItem value={'physical'}>Pay with physical card</MenuItem>
                                            <MenuItem value={'digital'}>Pay with digital card</MenuItem>
                                        </Select>
                                    </FormControl>
                                    }


                                    {paymentType === 'physical' && (
                                        <TextField
                                            fullWidth
                                            label="Enter approval code"
                                            value={approvalCode}
                                            onChange={(e) => setApprovalCode(e.target.value)}
                                        />
                                    )}

                                    {paymentType === 'digital' && (
                                        <>                              <Stack direction="row" spacing={2}>
                                            <CardNumber setCardType={setCardType} waitingResponse={waitingResponse} />
                                            <div className="divlogos">{cardSelector()}</div>
                                        </Stack>

                                            <Stack direction="row" spacing={2}>
                                                <Dateexpire
                                                    setexpireValidation={setexpireValidation}
                                                    waitingResponse={waitingResponse}
                                                />
                                                <CVV setCvvValidation={setCvvValidation} waitingResponse={waitingResponse} />
                                            </Stack>
                                            <ComplementariForm /></>
                                    )}



                                </div>

                                <input
                                    type="checkbox"
                                    checked={datacontext.Data.Datos.promociones_servicios}
                                    className="terms"
                                    value="checkbox"
                                    onChange={() => {
                                        datacontext.Updatespecificdata(
                                            'promociones_servicios',
                                            !datacontext.Data.Datos.promociones_servicios
                                        );
                                    }}
                                />
                                <Link
                                    className="terms"
                                    htmlFor="terms"
                                    onClick={() => {
                                        datacontext.Updatespecificdata(
                                            'promociones_servicios',
                                            !datacontext.Data.Datos.promociones_servicios
                                        );
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    {txt[63] ? txt[63] : 'I agree to receive promotions and information'}
                                </Link>
                            </ThemeProvider>
                        </>

                    </>
                )}

                {
                    paymentFail != null ? <Alert severity={"error"}>
                        <AlertTitle>{txt[33]}</AlertTitle>
                        {paymentFail}
                    </Alert> : <></>
                }
                <img className="divider" src={line} ></img>
                {id ? <><h1>Completing Transaction...</h1></> : <>
                    <div id="pagos">
                        <Amoount />
                        {waitingResponse ? <></> : <button className="stylebtn1" onClick={ManageApiAsk}>{txt[49]}</button>}
                    </div>
                </>}
            </div>
        </>
    );
}

export default TotalPayment;
