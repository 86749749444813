
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Principal from './components/Principal';
import NotFound from './components/NotFound';
import PrincipalMenu from './components/PrincipalMenu';
import TravelData from "./components/TravelData"
import NumberofUsers from './components/NumberofUsers';
import IdentityVerification from './components/IndentityVirification';
import CaptureVerification from './components/CaptureVerification';
import DataVerification from './components/DataVerification';
import IdentityConfirmation from './components/IndentityConfirmation';
import ConfirmationUsers from './components/ConfirmationUsers';
import TotalPayment from './components/TotalPayment';
import PaymentConfirmation from './components/PaymentConfirmation';
import AskforRecipt from './components/AskforRecipt';
import Dashboard from './components/Dashboard';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword';
import * as React from 'react';
import '../src/assets/styles/general.css'
import { lazy } from 'react'
import LoggedBar from './components/Universals/LoggedBar';
import PrivacyNotice from './components/PrivacyNotice/PrivacyNotice';

const Login = lazy(() => import(/* webpackChunkName: "LazyPage1" */'./components/Login'));



function App() {

  return (
    <Router>

      <LoggedBar/>
      <main>
        <Routes>
          <Route path='/' element={<Principal />} />
          <Route path='/PrincipalMenu' element={<PrincipalMenu />} />
          <Route path='/TravelData' element={<TravelData />} />
          <Route path='/NumberofUsers' element={<NumberofUsers />} />
          <Route path='/IdentityVerifications' element={<IdentityVerification />} />
          <Route path='/CaptureVerification' element={<CaptureVerification />} />
          <Route path='/DataVerification' element={<DataVerification />} />
          <Route path='/IdentityConfirmation' element={<IdentityConfirmation />} />
          <Route path='/ConfirmationUsers' element={<ConfirmationUsers />} />
          <Route path='/TotalPayment' element={<TotalPayment />} />
          <Route path='/PaymentConfirmation' element={<PaymentConfirmation />} />
          <Route path='/AskforRecipt' element={<AskforRecipt />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/ChangePassword' element={<ChangePassword />} />
          <Route path='/ResetPassword' element={<ResetPassword />} />
          <Route path='/PrivacyNotice' element={<PrivacyNotice />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>

    </Router>
  );
}

export default App;
