import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Context } from '../../context/Context';
import { useContext, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Title from './Title';

function BarChar({ Data }) {
    const datacontext = useContext(Context);
    const [xAxis, setXaxis] = useState([]);
    const [xYxis, setYaxis] = useState([]);





    useEffect(() => {
        setXaxis([]);
        setYaxis([]);
        try{
            Data.allTerminalRegister.map((value)=>{
                setXaxis(prevState => [...prevState, value.terminal]); // Añade la clave al array xAxis
                setYaxis(prevState => [...prevState, value.total]); // Añade el valor al array xYxis*/
            })
        }catch(e){}
       
    },[Data])


    return (
        <ThemeProvider theme={datacontext.primaytheme}>
            <Title>{"Sales by terminal"}</Title>
            <BarChart
                xAxis={[{ scaleType: 'band', data: xAxis }]}
                series={[{ data: xYxis, color: datacontext.primaytheme.palette.primary.main }]}

            />
        </ThemeProvider>

    );
} export default BarChar