import { Link } from 'react-router-dom';
import OK from "../../assets/Images/OK.png"
import arrow from "../../assets/Images/arrow-inicio.png"
import { useContext,useEffect } from 'react';
import { Context } from '../../context/Context'
import { useNavigate } from 'react-router-dom';
import HeaderNologo from '../Universals/HeaderNoLogo';
function IdentityConfirmation() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const navigate = useNavigate();
    useEffect(()=>{
        if (datacontext.Data.Datos.detalles.length===0) navigate("/TravelData");
    })  
    let name ="";
    let apellido ="";
    try {
         name = datacontext.Data.Datos.detalles[datacontext.userid].nombre;
         apellido = datacontext.Data.Datos.detalles[datacontext.userid].apellido;
      } catch (error) {console.log(error)}




    return (
    <div className="workzone">
        <HeaderNologo navigate="/DataVerification"/>
        <h1>{txt[25]}</h1>
        <p id='cardname'>{txt[21]+(datacontext.userid+1)}</p>
        <div className="pictureverification">
            <h4>{txt[25].toUpperCase()}</h4>
            <p id='cardname'>{name + " "+apellido}</p>
            <p id='form1'>{txt[41]}</p>
            <img id="ok" src={OK} ></img>
        </div>

        <Link style={{ textDecoration: 'none' }} to="/NumberofUsers" className="stylebtn1" >{txt[42]} <img id="continue" src={arrow} alt="Visitax logo"></img></Link>



    </div>)
} export default IdentityConfirmation;