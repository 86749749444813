
import visitaxlogo from "../../assets/Images/LogoVisitax.png"
import satq from "../../assets/Images/SatQ.png"
import visitaxof from "../../assets/Images/visitax-2.svg"
import edo from "../../assets/Images/edo-image.svg"
import divider from "../../assets/Images/divider-image.svg"
import { Link } from 'react-router-dom';
import reloj from "../../assets/Images/reloj.png"
import correo from "../../assets/Images/correo.png"
import payment from "../../assets/Images/check-img-btn.png"
import arrow from "../../assets/Images/arrow-inicio.png"
import blanconegro from "../../assets/Images/blanconegrovisitax.png"
import { Context } from '../../context/Context';
import { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import LenguageSelector from "../Universals/LenguageSelector"
import NavigationIcon from '@mui/icons-material/ArrowBack';

import ModalText from "../Universals/ModalText"

function PrincipalMenu() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];

    const [open, setOpen] = useState(false);

    const logos = () => {
        return (
            <div className="logos">
                <img className="satq" src={visitaxof} alt="Visitax logo"></img>
                <img id="divider" src={divider} alt="Visitax logo"></img>
                <img className="satq" src={edo} alt="Visitax logo"></img>
                <img id="divider" src={divider} alt="Visitax logo"></img>
                <img id="satQ" src={satq} alt="Visitax logo"></img></div>
        )
    }

    return (<div className="workzone">

        <ModalText open={open} setOpen={setOpen} type={2}></ModalText>
        <div className="backgroundhead1">
            <div className='backbutton'>

                <Link style={{ textDecoration: 'none' }} to="/">
                    <IconButton aria-label="back" size="large">
                        <NavigationIcon />
                    </IconButton>
                </Link>
            </div>
            <div className="logos">
                <img className="satq" src={satq} alt="Visitax logo"></img>
                <img id="divider" src={divider} alt="Visitax logo"></img>
                <img className="blanconegrovisitax" src={blanconegro} alt="Visitax logo"></img>
            </div>
        </div>
        <LenguageSelector />
        <p>{txt[2]}</p>
        <p>{txt[3]}</p>
        <Link style={{ margin: "auto", display: "block", textAlign: "center" }} className="terms" htmlFor="terms" onClick={() => { setOpen(true) }}>{"Privacity Notice"}</Link>
        <Link style={{ textDecoration: 'none' }} to="/TravelData" className="stylebtn2" > {txt[9]} <img id="payment" src={payment} alt="Visitax logo"></img></Link>
        <Link style={{ textDecoration: 'none' }} to="/AskforRecipt" className="stylebtn3" >{txt[10]}<img id="continue" src={arrow} alt="Visitax logo"></img></Link>
        <p>{txt[4]}</p>
        <div style={{ textAlign: 'center' }}>
            <img src={reloj} style={{ paddingRight: "10px" }} alt="Visitax logo" />
            <strong id='contactbold'>{txt[5]}</strong>
            <p id='contact'>{txt[6]}</p>
        </div>
        <div style={{ textAlign: 'center' }}>
            <img src={correo} alt="Visitax logo" style={{ paddingRight: "10px" }} />
            <strong id='contactbold'>{txt[7]}</strong>
            <p id='contact'>{txt[8] + " " + datacontext.contact.email}</p>
            <p id='contact'>{datacontext.contact.adress}</p>
            <p id='contact'>{datacontext.contact.phone}</p>

        </div>
    </div>)

} export default PrincipalMenu;