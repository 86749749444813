import { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import { createDocument, updateDocument, deleteDocument, readDocumentById } from "../../../context/puchdb/pouchdbData";
import { Kushki } from "@kushki/js";

export const useKushkiPayment = ({ setPaymentFail, setWaitingResponse }) => {
    const datacontext = useContext(Context);
    const dbId = "dataPayment"
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [kushki, setkushki] = useState(null);

    const [tokenid, setTokenid] = useState(null);


    const ConfigureApiKushki = () => {
        try {
            datacontext.myConfig.allconfigArray.map((value) => {
                if (value.concept.includes("PublicMerchantId")) {


                    setkushki(new Kushki({
                        merchantId: value.configurationInfo,
                        inTestEnvironment: true,
                        regional: false
                    }))
                }
            })
        } catch (e) {
            console.log(e);
        }
    }



    var callback3dsvalidate = function(response) {
        if(!response.code){
          console.log(response);
          setState(3);
        } else {
          console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
          FailResponsePay("Authentication error occurred");
        }
      }

    const successCallbacktoken = async (response) => {
        setTokenid(response.token);
        kushki.requestValidate3DS({
            secureId: response.secureId,
            security: response.security},callback3dsvalidate);

      
    };


    var callbackkushkitoken = function (response) {
        if (!response.code) {
            successCallbacktoken(response);
        } else {
            console.error('Error: ', response.error, 'Code: ', response.code, 'Message: ', response.message);
            FailResponsePay(txt[48]);
        }
    }

    const CreateToken = () => {
        console.log("Creando Token");
        const bodykushki = {
            card: {
                "name": datacontext.card.holder_name,
                "number": datacontext.card.card_number,
                "expiryMonth": datacontext.card.expiration_month,
                "expiryYear": datacontext.card.expiration_year,
                "cvc": datacontext.card.cvv2,
            },
            amount: datacontext.Data.Datos.importe,
            currency: "MXN",
            authValidation: "url",
            callbackUrl: "http://localhost:3000/TotalPayment"
        }

        kushki.requestToken(bodykushki, callbackkushkitoken);
    }






    const OKResponsePay = (comprobante) => {

        datacontext.setComprobante(comprobante);
        navigate("/PaymentConfirmation");
        setPaymentFail(null);
        setWaitingResponse(false);


    }
    const FailResponsePay = (message = "An error occurred while processing your payment") => {
        setPaymentFail(message);
        setWaitingResponse(false);
        setState(0);
    }


    const manageResponse = (response) => {
        try {

            switch (response.status) {
                case 200:
                    OKResponsePay(response.data.data.data[0].comprobante);
                    break;
                case 201:
                    OKResponsePay("Empty Comprobant:" + response.data.data.details.body);
                    break;
                case 202:
                    OKResponsePay("Empty Comprobant:" + response.data.data.details.body);
                    break;
                case 400:
                    FailResponsePay(txt[48]);
                    break;
                default:
                    FailResponsePay(txt[48]);
                    break;
            }


        } catch (e) { console.log(e) }


    }

    const CompletePayment = (token) => {
        datacontext.postPayment(manageResponse, {
            ...datacontext.Data.Datos,
            card:{
                name:datacontext.card.holder_name,
                token:tokenid
            }

        },2);
    }

    const configureApiKushki = () => {
        setState(1);
    }
    const makePaymentkushi = () => {
        console.log("Creando Pago");
        setState(2);
    }









    useEffect(() => {
        switch (state) {
            case 1:
                ConfigureApiKushki();
                break;
            case 2:
                console.log("Entrando el segundo Pago");
                CreateToken();
                break;
            case 3:
                CompletePayment();
                break;


        }
    }, [state])


    return {
        configureApiKushki,
        makePaymentkushi,
    }
}