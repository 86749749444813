import { useContext,useState,useEffect } from "react";
import { Context } from "../../../context/Context";
export const useManageForm = () => {
    const datacontext = useContext(Context);
    const nameregex = /^[a-zA-Z\s]*$/;

    const UpdatepersonalData = (key, value) => {
        const Person = {
            ...datacontext.Data.Datos.detalles[datacontext.userid],
            [key]: value,
        }
        datacontext.UpdatePersonValue(Person);
    }
    

    const [FormData,setFormData] =useState ({
         name : "",
         surname : "",
         passport : "",
         departureDate : "",
         email : "",
         phone : "",
         nationality : ""
    })

    useEffect(()=>{
        console.log(datacontext.Data.Datos.detalles);
        try{
            setFormData({
                name: datacontext.Data.Datos.detalles[datacontext.userid].nombre,
                surname : datacontext.Data.Datos.detalles[datacontext.userid].apellido,
                passport : datacontext.Data.Datos.detalles[datacontext.userid].pasaporte,
                departureDate : datacontext.Data.Datos.detalles[datacontext.userid].fecha_salida,
                email : datacontext.Data.Datos.detalles[datacontext.userid].correo,
                phone : datacontext.Data.Datos.detalles[datacontext.userid].telefono,
                nationality : datacontext.Data.Datos.detalles[datacontext.userid].nacionalidad,
            })

        }catch(e){}

    },[datacontext.Data.Datos.detalles])


    

    const UpdateFormData = (key = "", event = "") => {
        let update = false;
        let value = "";
        switch (key) {
            case "nacionalidad":
                value = event;
                update = true;
                break;
            case "nombre":
                value = event.target.value;
                if (nameregex.test(event.target.value) && value.length<30 ) update = true;
                   break;
            case "apellido":
                value = event.target.value;
                if (nameregex.test(event.target.value) && value.length<30) update = true;
                break;
            case "pasaporte":
                value = event.target.value;
                if(value.length<14)update = true;
                break;
            case "correo":
                value = event.target.value;
                update = true;
                break;
            case "telefono":
                value = event.target.value;
                update = true;
                break;
        }
        if (update) {UpdatepersonalData(key, value); update = false;}

    }

    return{UpdateFormData,FormData}


}