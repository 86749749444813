import { useContext, useEffect, useRef } from 'react';
import { Context } from '../../context/Context';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { NotificationContext } from "../../context/NotificationContext";

function CameraViewer() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null); // Referencia a un elemento canvas para dibujar la imagen capturada
  const datacontext = useContext(Context);
  const navigate = useNavigate();
  const streamRef = useRef(null); // Referencia al stream de video
  const { askForNotificationPermission } = useContext(NotificationContext);
  useEffect(() => {
   

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: "environment" // Intenta seleccionar la cámara trasera
          }
        })
          .then((stream) => {
            streamRef.current = stream; // Guardar el stream en la referencia
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
            }
            askForNotificationPermission();
          })
          .catch((error) => {
            // Si no se puede acceder a la cámara trasera, intenta con la cámara frontal
            navigator.mediaDevices.getUserMedia({
              video: {
                facingMode: "user" // Selecciona la cámara frontal
              }
            })
              .then((stream) => {
                streamRef.current = stream; // Guardar el stream en la referencia
                if (videoRef.current) {
                  videoRef.current.srcObject = stream;
                }
                askForNotificationPermission();
              })
              .catch((error) => {
                console.error('Error accessing media devices.', error);
                askForNotificationPermission();
              });
          });
      }


  }, []);


  const stopVideoStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
  };

  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      // Convertir la imagen del canvas a un archivo
      canvas.toBlob((blob) => {
        const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });

        // Aquí puedes usar el archivo de la misma manera que lo hacías antes
        datacontext.setFile(file);
        stopVideoStream(); // Detener el stream de video después de capturar la foto
        navigate('/CaptureVerification');
      }, 'image/jpeg');
    }
  };

  return (
    <div>
      <video ref={videoRef} autoPlay style={{ padding: "20px", top: 0, left: 0, width: '80%', height: '50%' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} /> {/* El canvas está oculto */}
      <div onClick={capturePhoto} style={{ textDecoration: 'none' }} to="/CaptureVerification" className="stylebtn1" >
        <Button >
          <CameraAltIcon color="action" />
        </Button></div>

    </div>
  );
}

export default CameraViewer;