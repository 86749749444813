const today = new Date();


export function month() {
        const month = today.getMonth() + 1;
        return (month < 10) ? ("0" + month) : month
}
function date() {
        const date = today.getDate();
        return (date < 10) ? ("0" + date) : date
}
export function year() {
        const year = today.getFullYear();
        return (year < 10) ? ("0" + year) : year
}
function currentDate() { return year() + "-" + month() + "-" + date(); }
function currentDateDataBase() { return year() + "/" + month() + "/" + date(); }


export let Datos = {
        "pais_id": 0,
        "terminal_id": 0,
        "plataforma_pago_id": 0,
        "moneda_iso": "mxn",
        "fecha_ingreso": currentDate(),
        "fecha_salida": "",
        "personas": 0,
        "importe": 0,
        "archivo": "",
        "descripcion": "",
        "detalles": [],
        "pagado": 0,
        "card": {
                "name": "",
                "token": "",
        },
        "extra_charges": [],
        "transaction_id": "",
        "terminal": "",
        "correo": "",
        "telefono": "",
        "promociones_servicios":true,
        "push_token":""
}

export let openpay3ds = {
        "source_id": "",
        "device_session_id": "",
        "method": "card",
        "amount": 0,
        "description": "",
        "use_3d_secure": true,
        "redirect_url": "https://visitaxonline.com/TotalPayment",
        "customer": {
                "name": "",
                "last_name": "",
                "phone_number": "",
                "email": ""
        },
        //"confirm": "true",
        "send_email": "false",
        //"capture": false
}

export let Person = {
        "nombre": "",
        "apellido": "",
        "edad": 90,
        "correo": "",
        "pasaporte": "",
        "importe": 0,
        "fecha_ingreso": currentDate(),
        "fecha_salida": "",
        "nacionalidad": "",
        "telefono": "",

}

export let User = {
        "name": "",
        "lastname": "",
        "username": "",
        "id": "",
        "token": ""
}
export const getCurrentDate = () => {
        return currentDateDataBase();
}
export const RolesKey = {
        "Operador": "94DF578F-CAF9-449A-B4FC-17BFF652319E",
        "Campo": "3354688A-A119-40D9-9C27-577F7885B277",
        "Administrador": "DDFA3BA3-78C3-4AD3-953C-695931125B1B",

}

export const constRoles = ["Campo", "Administrador", "Operador"]

