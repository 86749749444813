import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf, Font } from '@react-pdf/renderer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import SimpleBackdrop from '../../Universals/BackDrop';
import logoDr from '../../../assets/Images/DRLogoOficial.png'
import HojaMembretada from '../../../assets/Images/Hoja Membretada.png'
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        fontFamily: ""
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: "right",
        marginLeft: 160,
    },
    subtitle: {
        fontSize: 15,
        marginBottom: "10px",
        marginTop: "0px",
        fontWeight: 'bold',
        textAlign: "center",

    },
    Values: {
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 'bold',
        textAlign: "center",
    },
    dates: {
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "40px",

    },
    logo: {
        width: 50,
        height: 50,
    },
    watermark: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.7,
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        position: 'absolute',
        bottom: 25,
        left: 0,
        right: 0,
        textAlign: 'right',
        fontSize: 10,
        marginRight: "35px",
        fontWeight: "light",
        opacity: 0.5,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        display: "flex"

    },
    graphSection: {
        border: " 1px solid #000",
        flex: 0,
        margin: "20px"
        // Estilos para la sección del gráfico
    },
    tableSection: {
        //border: " 1px solid #000",
        flex: 4,
        borderWidth: 0,
        // Estilos para la sección de la tabla
    },
    totalAmount: {

        margin: "5px",
        flex: 0.5,
        display: "flex",
        flexDirection: "row",
        margin: "15px auto 0px auto",
        width: "400px",
        padding: 0
        // Estilos para la sección de la tabla
    },
    item: {
        margin: "5px",
        flex: "1",

    },

    table: {
        display: "table",
        borderWidth: 0,
        margin: "15px auto 0px auto",
    },
    row: {
        flexDirection: "row",
        display: "flex",
        margin: "0px auto 0px auto",
        width: "400px",
        borderWidth: 1,
    },
    cell: {
        margin: "0",
        borderStyle: "solid",
        textAlign: "center",
        flex: "1",

    },
});
// Crea un componente de celda de tabla
const TableCell = ({ children }) => (
    <View style={styles.cell}>
        <Text>{children}</Text>
    </View>
);

// Crea un componente de fila de tabla
const TableRow = ({ children }) => <View style={styles.row}>{children}</View>;
const tabledata = (data) => {
    let datosorted = [];


    if (data.DepositsData.daySums != null) {
        data.DepositsData.daySums.map((value, id) => {
            datosorted.push([value.date, value.sum]);
        })
    }

    if (data.DepositsData.hourSums != null) {
        data.DepositsData.hourSums.map((value, id) => {
            datosorted.push([value.hour < 10 ? value.date + " h: 0" + value.hour : value.date + " h: " + value.hour,
            value.sum]);
        })
    }

    if (data.DepositsData.monthSums != null) {
        data.DepositsData.monthSums.map((value, id) => {
            datosorted.push([value.monthNumber < 10 ? "0" + value.monthNumber + "/" + value.year : value.monthNumber + "/" + value.year,
            value.sum]);

        })
    }
    datosorted.sort((a, b) => {
        if (a[0] < b[0]) {
            return -1;
        }
        if (a[0] > b[0]) {
            return 1;
        }
        return 0;
    });


    return datosorted;
}
const MyDocument = ({ data, dates }) => (

    <Document>
        <Page style={styles.page} size="A4">
            <View style={styles.watermark}>
                <Image src={HojaMembretada} />
            </View>
            <Text style={styles.footer}>
                Calle Becerra 70-B Col. Tacubaya{'\n'}
                Alcaldía Miguel Hidalgo C.P. 11870{'\n'}
                Tel. (55) 5511 6508 • contacto@drsecurity.net{'\n'}
                www.drsecurity.net
            </Text>

            <View style={styles.header}>
                <Text style={styles.title}>Reporte de Registros</Text>
                <View>
                    <Text style={styles.dates}>Desde: {dates.start}</Text>
                    <Text style={styles.dates}>Hasta: {dates.end}</Text>
                </View>

            </View>

            <View style={styles.section}>

                <View style={styles.totalAmount}>
                    <View style={styles.item}>
                        <Text style={styles.subtitle}>Ventas:</Text>
                        <Text style={styles.Values}>{'$' + data.DepositsData.totalSum}</Text>
                    </View>
                    <View style={styles.item}>
                        <Text style={styles.subtitle}>Transacciones:</Text>
                        <Text style={styles.Values}>{data.ListData.totalSum}</Text>
                    </View>
                </View>


                <View style={styles.totalAmount}>


                    <View style={styles.item}>
                        <Text style={styles.subtitle} >Personas:</Text>
                        <Text style={styles.Values}>{data.ListData.totalPerson}</Text>
                    </View>
                    <View style={styles.item}>
                        <Text style={styles.subtitle} >Incidentes:</Text>
                        <Text style={styles.Values}>{data.ListData.vouchernull}</Text>
                    </View>




                </View>

                <View style={styles.tableSection}>
                    <View style={styles.table}>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                        {tabledata(data).map((value, id) => {

                            return (<>
                                <TableRow>
                                    <TableCell>{value[0]}</TableCell>
                                    <TableCell>{value[1]}</TableCell>
                                </TableRow>

                            </>)
                        })}




                    </View>
                </View>
            </View>

            {/* ... otros componentes */}

            {/* Aquí va el contenido del documento */}
        </Page>
    </Document>
);

const exportToExcel = (Data) => {
    // Crear un nuevo libro de trabajo
    let wb = XLSX.utils.book_new();

    // Crear algunos datos
    let data = [
        ["Consecutivo", "Voucher", "Id de pasarela", "Fecha de creación", "Numero de registros", "Valor de impuestos ", "Cargo por Servicio", "Cantidad"]
    ];

    if (Data.ListData != null) {
        Data.ListData.allPaymentRegister.map((value, id) => {
            data.push([id, value.voucher, value.openpayKey, value.datecreate, value.numberofRecords, "$" + (value.amount - value.serviceCharge), "$" + value.serviceCharge, "$" + value.amount])
        })
    }


    // Convertir los datos a una hoja de trabajo
    let ws = XLSX.utils.aoa_to_sheet(data);

    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

    // Escribir el libro de trabajo en un archivo
    XLSX.writeFile(wb, "libro.xlsx");
};

const exportToExcel2 = (Data) => {
    // Crear un nuevo libro de trabajo
    let wb = XLSX.utils.book_new();

    // Crear algunos datos
    let data = [
        ["Consecutivo", "Voucher", "HashId", "Pasaporte", "Fecha de creación", "Cantidad"]
    ];

    if (Data.TransactionDetailsData != null) {
        Data.TransactionDetailsData.map((value, id) => {
            data.push([id, value.voucher, value.hashId, value.passport, value.createdAt, "$" + value.amount]);
        })
    }


    // Convertir los datos a una hoja de trabajo
    let ws = XLSX.utils.aoa_to_sheet(data);

    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

    // Escribir el libro de trabajo en un archivo
    XLSX.writeFile(wb, "libro.xlsx");
};

function Reports({ report, data }) {
    const [fileUrl, setFileUrl] = React.useState(null);
    let FilterUsed = "";
    if (data.filter.includes("Today")) FilterUsed = data.FiltersData[0];
    if (data.filter.includes("Month")) FilterUsed = data.FiltersData[1];
    if (data.filter.includes("Year")) FilterUsed = data.FiltersData[2];
    if (data.filter.includes("Select")) FilterUsed = data.FiltersData[3];
    let startDate = "";
    let endDate = "";
    try {
        startDate = FilterUsed.split("StartDate=")[1].split("&")[0];
        endDate = FilterUsed.split("EndDate=")[1].split("&")[0];
    } catch (e) {
        console.log(e)
    }


    console.log(data);


    React.useEffect(() => {
        setFileUrl(null);
        if (report.includes('pdf')) {
            if (data.DepositsData != null && data.ListData != null)
                pdf(<MyDocument data={data} dates={{ start: startDate, end: endDate }} />).toBlob().then(blob => {
                    setFileUrl(URL.createObjectURL(blob));
                });
        }
    }, [data]);

    return (


        <div>
            {report.includes('pdf') ?
                <>
                    {fileUrl ? <iframe src={fileUrl} width="100%" height="650px" /> : <SimpleBackdrop complete={false} />}
                </>
                : <>
                    {
                        data.ListData != null ? <>

                            <Grid container spacing={2}>

                                <Grid item xs={10} md={2} lg={2}>
                                    <Button variant="outlined" startIcon={<DownloadIcon />} onClick={() => { exportToExcel(data) }}>
                                        Export to Excel by Transaction
                                    </Button>
                                </Grid>

                                <Grid item xs={5} md={2} lg={2}>
                                    <Button variant="outlined" startIcon={<DownloadIcon />} onClick={() => { exportToExcel2(data) }}>
                                        Export to Excel by Passport
                                    </Button>
                                </Grid>
                            </Grid>



                        </>


                            : <SimpleBackdrop complete={false} />
                    }
                </>

            }

        </div>


    );

} export default Reports;