import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';

import Title from './Title';

// Generate Sales Data
function createData(time, amount) {
  return { time, amount: amount ?? null };
}


export default function Chart({data,type}) {
  const theme = useTheme();
  const Data = [


  ];
  const datasort = (data)=>{
    data.sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });
    
    return data

  }

  try{
    let valuesum =0;
    
    if (data.daySums != null) {
      
      datasort(data.daySums).map((value) => {
        switch(type){
          case 1:
            Data.push(createData(value.date, value.sum));
            break;
          case 2:
            valuesum += value.sum
            Data.push(createData(value.date, valuesum));
            break;
        }
     
      })
    }
    if (data.monthSums != null) {
      datasort(data.monthSums).map((value) => {
        switch(type){
          case 1:
            Data.push(createData(value.monthNumber+"/"+value.year, value.sum));
            break;
          case 2:
            valuesum += value.sum
            Data.push(createData(value.monthNumber+"/"+value.year, valuesum));
            break;
        }
        
      })
    }
    if (data.yearSums != null) {
      datasort(data.yearSums).map((value) => {
        switch(type){
          case 1:
            Data.push(createData(value.monthNumber+"/"+value.year, value.sum));
            break;
          case 2:
            valuesum += value.sum
            Data.push(createData(value.monthNumber+"/"+value.year, valuesum));
            break;
        }
        
      })
      
    }
    if (data.hourSums != null) {

      datasort(data.hourSums).map((value) => {
        switch(type){
          case 1:
            Data.push(createData(value.hour<10?"0"+value.hour+":00":value.hour+":00", value.sum));
            break;
          case 2:
            valuesum += value.sum
            Data.push(createData(value.hour<10?"0"+value.hour+":00":value.hour+":00", valuesum));
            break;
        }
       
      })
      
    }


  }catch(e){
     }




  return (
    <React.Fragment >
      <Title></Title>
      <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
        <LineChart
          dataset={Data}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: 'point',
              dataKey: 'time',
              tickNumber: 2,
              tickLabelStyle: theme.typography.body2,
            },
          ]}
          yAxis={[
            {
              label: 'Sales ($)',
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2,
              
              tickNumber: 3,
            },
          ]}
          series={[
            {
              dataKey: 'amount',
              showMark: false,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}