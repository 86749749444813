import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../../data/Countries";
import { Grid } from "@mui/material";
import { Context } from '../../context/Context';
import { useContext, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';

const CountrySelector = ({ initialValue,UpdateFormData }) => {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        if (initialValue) {
            const initialCountry = countries.find(country => country.passportCode === initialValue);
            setSelectedCountry(initialCountry);
        }
    }, [initialValue]);

    return (
        <ThemeProvider theme={datacontext.primaytheme}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    border: "none",
                    borderRadius: "24px",
                    marginTop: "40px"
                }}
            >
                <Autocomplete
                    id="country-select-demo"
                    sx={{
                        fontSize: "15px",
                        width: "82%",
                        backgroundColor: "white",
                        borderRadius: "24px",
                        '.MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none', // Quitar el borde del TextField
                            },
                        },
                        '.MuiAutocomplete-popupIndicator': {
                            color: 'transparent', // Quitar el borde del icono desplegable
                        },
                    }}
                    options={countries}
                    autoHighlight
                    value={selectedCountry}
                    onChange={(event, newValue) => {
                
                        setSelectedCountry(newValue);
                        try{
                            UpdateFormData("nacionalidad",newValue.passportCode);
                        }catch(e){
                            UpdateFormData("nacionalidad","");
                        }
                        
                    }}
                    getOptionLabel={(option) => option.nationality}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                        >
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.nationality} ({option.passportCode}) 
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={txt[62] ? txt[62] : "Choose a nationality"}
                            sx={{
                                border: "none",
                                borderRadius: "24px",
                                '& .MuiInputBase-input': {
                                    textAlign: 'center', // Centrando el texto
                                    color: 'rgb(149, 47, 87)', // Color del texto seleccionado
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: '30px',
                                    textAlign: 'center',
                                    fontFamily: 'Candara',
                                    color: 'rgb(149, 47, 87)',
                                    margin: 0,
                                    width: '100%',
                                    left: '0',
                                    transformOrigin: 'center', // Centra el label
                                    fontWeight: "bold",
                                },
                                '& .MuiInputLabel-shrink': {
                                    transform: 'translate(0, -35px) scale(0.75)', // Ajustar la posición cuando está shrink
                                },
                                '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none', // Eliminar borde al enfocar
                                },
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {params.inputProps.value && (
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${countries.find(c => c.nationality === params.inputProps.value.split(" ")[0])?.code.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${countries.find(c => c.nationality === params.inputProps.value.split(" ")[0])?.code.toLowerCase()}.png 2x`}
                                                alt=""
                                                style={{ marginRight: 8, color: 'rgb(149, 47, 87)' }}
                                            />
                                        )}
                                        {params.InputProps.startAdornment} 
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        </ThemeProvider>
    )
}

export default CountrySelector;
