import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';

function MainList(props) {
    const DashBoardClick = () => {
        props.setActualView("Dashboard");
    }

    const UsersClick = () => {
        props.setActualView("Users");
    }
    const ReportsClick = () => {
        props.setActualView("Reports");
    }
    const ConfigurationsClick = () => {
        props.setActualView("Configurations");
    }



    const mainListItems = (
        <React.Fragment>
            <ListItemButton onClick={DashBoardClick}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton onClick={ReportsClick}>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItemButton>
            <ListItemButton onClick={UsersClick}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>

            <ListItemButton onClick={ConfigurationsClick}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configurations" />
            </ListItemButton>
        </React.Fragment>
    );
    return (<List component="nav">
        {mainListItems}
    </List>)
} export default MainList;